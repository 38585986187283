import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useModalStore = defineStore('modal', () => {
  const showDemoModal = ref(false)
  
  // 监听模态框状态变化
  watch(showDemoModal, (newValue) => {
    if (newValue) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  })
  
  const openDemoModal = () => {
    showDemoModal.value = true
  }
  
  const closeDemoModal = () => {
    showDemoModal.value = false
  }

  return {
    showDemoModal,
    openDemoModal,
    closeDemoModal
  }
}) 